<template>
  <b-card>
    <titulo-btns title="FINANCEIRO.TAXAS">
      <b-button variant="primary" class="mr-2" @click="openModal('taxa')">
        {{ $t('FINANCEIRO.CADASTRAR_TAXA') }}
      </b-button>
      <b-button variant="danger" @click="irPara('financeiro-estrutura')">
        {{ $t('FINANCEIRO.RETORNAR') }}
      </b-button>
    </titulo-btns>

    <Tabela :items="items" @editar="openModal" @excluir="confirmarExclusao" />

    <modal-taxa
      :form="modais.taxaDados"
      :exibir="modais.taxa"
      @salvar="salvar"
      @fechar="closeModal('taxa')"
    />
  </b-card>
</template>
<script>
// Utils & Aux:
import mensagem from '@/common/utils/mensagem';
import breadcrumb from '@/common/utils/breadcrumb';
import rotas from '@/common/utils/rotas';

// Components:
import TituloBtns from '@/components/headers/TituloBtns';
import Tabela from './components/Tabela';
import ModalTaxa from './components/Modal';

export default {
  components: {
    TituloBtns,
    Tabela,
    ModalTaxa,
  },
  data() {
    return {
      items: [
        {
          descricao: 'Taxa matrícula',
          valor: '130',
          formaPagamento: 'Boleto',
        },
      ],
      modais: {
        taxa: false,
        taxaDados: {},
      },
    };
  },
  mounted() {
    breadcrumb.definir(this.$store, [
      { titulo: 'FINANCEIRO.CONFIGURACOES' },
      { titulo: 'FINANCEIRO.FINANCEIRO' },
      { titulo: 'FINANCEIRO.TAXAS' },
    ]);
  },
  methods: {
    // FUNÇÕES CRUD:
    salvar(item) {
      item.id ? this.editar(item) : this.criar(item);
    },
    criar() {},
    editar() {},
    async confirmarExclusao(item) {
      const respostaExclusao = await mensagem.confirmacao(
        'Confirmar exclusão',
        'Você quer mesmo deletar isso aqui?'
      );
      respostaExclusao.isConfirmed ? this.excluir(item) : {};
    },
    excluir() {},
    // FUNÇÕES MODAL
    openModal(key, item) {
      this.modais[key] = true;
      item
        ? (this.modais[`${key}Dados`] = JSON.parse(JSON.stringify(item)))
        : (this.modais[`${key}Dados`] = {});
    },
    closeModal(key) {
      return (this.modais[key] = false);
    },
    // FUNÇÕES AUXILIARES:
    irPara(rota, id) {
      rotas.irPara(rota, id);
    },
  },
};
</script>
