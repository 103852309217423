<template>
  <div class="row">
    <div class="col-12 col-md-12">
      <input-text
        ref="descricao"
        v-model="form.descricao"
        type="text"
        :label="$t('FINANCEIRO.DESCRICAO')"
        :placeholder="$t('FINANCEIRO.TIPO')"
        required
      />
    </div>
    <div class="col-12 col-md-6">
      <input-text
        ref="valor"
        v-model="form.valor"
        type="text"
        :label="$t('FINANCEIRO.VALOR')"
        :placeholder="$t('FINANCEIRO.VALOR')"
        required
      />
    </div>
    <div class="col-12 col-md-6">
      <input-text
        ref="formaPagamento"
        v-model="form.formaPagamento"
        type="text"
        :label="$t('FINANCEIRO.FORMA_PAGAMENTO')"
        :placeholder="$t('FINANCEIRO.FORMA_PAGAMENTO')"
        required
      />
    </div>
  </div>
</template>
<script>
import { InputText } from '@/components/inputs';
export default {
  props: {
    form: { type: Object, default: Object },
  },
  components: {
    InputText,
  },
  mounted() {
    this.$emit('refs', this.$refs);
  },
};
</script>
