<template>
  <modal
    id="modalBancos"
    titulo="FINANCEIRO.PRECOS"
    :exibir="exibir"
    :campos="$refs"
    @fechar="fecharModal"
  >
    <form-precos :form="form" @refs="getRefs" />

    <template #modal-footer>
      <b-button variant="secondary" @click="fecharModal">
        {{ $t('GERAL.FECHAR') }}
      </b-button>
      <b-button variant="primary" @click="salvar">
        {{ $t('GERAL.SALVAR') }}
      </b-button>
    </template>
  </modal>
</template>

<script>
// Utils & Aux:
import helpers from '@/common/utils/helpers';

// Components:
import Modal from '@/components/modal/Modal';
import FormPrecos from './Form';

export default {
  props: {
    exibir: { type: Boolean, default: false },
    form: { type: Object, default: Object },
  },
  components: {
    Modal,
    FormPrecos,
  },
  data() {
    return {
      refs: [],
    };
  },
  methods: {
    // FUNÇÕES VALIDAÇÃO FORMULÁRIO:
    getRefs(refs) {
      this.refs = refs;
    },
    validarFormulario() {
      return helpers.validarFormulario(this.refs);
    },
    // FUNÇÕES MODAL:
    fecharModal() {
      this.$emit('fechar');
    },
    salvar() {
      if (!this.validarFormulario()) return;
      this.$emit('salvar', this.form);
    },
  },
};
</script>
